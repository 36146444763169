import React, { useState, useEffect, useRef } from 'react';
import * as styles from '../Career/styles/career.module.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Layout from '../components/Layout';
import { graphql, Link } from 'gatsby';

export default function Career({ data }) {
  const career = data.allJobDummyJson.nodes;

  const [off, setOff] = useState(0);

  const handleScroll = () => setOff(window.pageYOffset);

  const tl = gsap.timeline();
  gsap.registerPlugin(ScrollTrigger);

  let careerBanner = useRef(null);
  let careerSection = useRef(null);
  let careerSection_two = useRef(null);
  let careerSection_three = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // useEffect(() => {
  //   tl.from(careerSection, 1, {
  //     y: 100,
  //     opacity: 0,
  //     ease: 'power3.easeOut',
  //     delay: 0.3,
  //     stagger: {
  //       amount: 0.4,
  //     },
  //     scrollTrigger: {
  //       trigger: careerBanner,
  //       start: '50%',
  //       end: '100%',
  //       pinSpacing: false,
  //       scrub: true,
  //     },
  //   })
  //     .from(careerSection_two, 1, {
  //       y: 100,
  //       opacity: 0,
  //       ease: 'power3.easeOut',
  //       delay: 0.3,
  //       stagger: {
  //         amount: 0.4,
  //       },
  //       scrollTrigger: {
  //         trigger: careerSection,
  //         start: '50%',
  //         end: '100%',
  //         pinSpacing: false,
  //         scrub: true,
  //       },
  //     })
  //     .from(careerSection_three, 1, {
  //       y: 100,
  //       opacity: 0,
  //       ease: 'power3.easeOut',
  //       delay: 0.3,
  //       stagger: {
  //         amount: 0.4,
  //       },
  //       scrollTrigger: {
  //         trigger: careerSection_two,
  //         start: '0%',
  //         end: '100%',
  //         pinSpacing: false,
  //         scrub: true,
  //       },
  //     });
  // }, []);

  return (
    <Layout>
      <div className={styles.career_wrapper}>
        <div
          className={styles.career_banner}
          ref={(el) => {
            careerBanner = el;
          }}>
          <h1 className={styles.career_banner_bg_txt} data-text='CAREERS'>
            CAREERS
          </h1>
          <div
            className={styles.career_banner_content}
            style={{ transform: `translateY(${off * -0.9}px)` }}>
            <h3 className={styles.career_banner_content_header}>
              We Are Expanding!
            </h3>
            <p className={styles.career_banner_content_text}>
              We are always on the lookout for established or emerging talent
              eager to work with some of the most exciting clients in the world.
              If you think you'd fit in, reach out.
            </p>
            <button className={styles.career_banner_btn}>
              see open positions
            </button>
          </div>
        </div>
        <div className={styles.career_content_section_wrapper}>
          <div
            className={styles.career_content_inner}
            ref={(el) => {
              careerSection = el;
            }}>
            <h3 className={styles.career_inner_contents_header_mb}>
              Why work here?
            </h3>
            <div className={styles.career_inner_img}></div>
            <div className={styles.career_inner_contents_wrapper}>
              <h3 className={styles.career_inner_contents_header}>
                Why work here?
              </h3>
              <div className={styles.career_inner_contents}>
                <h4>Your work, experienced by millions</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim
                  dolor nibh amet, nec turpis duis ullamcorper eu, risus. Ipsum.
                </p>

                <h4>Growing with purpose</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. A a
                  vulputate nunc enim malesuada quis tincidunt at. Volutpat
                  posuere eget scelerisque tincidunt enim. Porta eu quisque
                  vitae sed lorem volutpat quisque. Blandit volutpat lacinia.
                </p>

                <h4>Values-driven</h4>
                <p>
                  Our work, and the way we work, is grounded in and driven by
                  our values. We take care of each other and go further
                  together.
                </p>

                <h4>Disconnect {'&'} recharge</h4>
                <p>
                  We want you to be excited to be here. But we also want you to
                  take time off to recharge, focus on your personal life. Happy
                  colleagues make better experiences.
                </p>

                <h4>Build a career here</h4>
                <p>
                  We believe in open, honest, two-way communication and
                  understanding of our colleagues' career aspirations. Growth
                  means moving into new roles, exposure to multiple disciplines
                  and teams, and having an impact in different ways.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.career_content_section2_wrapper}>
          <div className={styles.career_content_section2_inner}>
            <div
              className={styles.career_content_section2_inner_wrapper}
              ref={(el) => {
                careerSection_two = el;
              }}>
              <div className={styles.career_section_flex1}>
                <h3>Our Team</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu
                  vulputate eget enim ipsum. Euismod nec vitae varius pharetra.
                  Netus ultricies maecenas posuere praesent vulputate leo
                  rhoncus. Arcu iaculis pretium quisque pretium risus, euismod
                  vitae venenatis. Ipsum et aenean amet, vestibulum in netus mi,
                  justo, risus.
                </p>
              </div>
              <div className={styles.career_section_flex2}>
                <h3>Our Values</h3>
                <div className={styles.inner_flex}>
                  <img src='/dollar.png' alt='' />
                  <p>Efficiency first</p>
                </div>
                <div className={styles.inner_flex}>
                  <img src='/clock.png' alt='' />
                  <p>Remote team</p>
                </div>
                <div className={styles.inner_flex}>
                  <img src='/tool.png' alt='' />
                  <p>Support driven</p>
                </div>
                <div className={styles.inner_flex}>
                  <img src='/tool.png' alt='' />
                  <p>A focus on craft</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.career_image_wrapper}
            ref={(el) => {
              careerSection_three = el;
            }}>
            <div className={styles.earth}>
              <img src='/earth.png' alt='' />
            </div>
            <div className={styles.career_image_text}>
              <h3>Humans Helping Humans</h3>
              <p>A team that is as wide as it is diverse</p>
            </div>
          </div>
          <div className={styles.bottomflex_wrapper}>
            <div className={styles.bottomflex_inner}>
              <div>
                <h3>6</h3>
                <p>Countries</p>
              </div>
              <div>
                <h3>6</h3>
                <p>Teammates</p>
              </div>
              <div>
                <h3>6</h3>
                <p>Languages</p>
              </div>
            </div>
          </div>
          <div className={styles.career_offer_wrapper}>
            <h3>What We Offer</h3>
            <div className={styles.career_offer_content}>
              <div className={styles.career_offer_content_inner}>
                <img src='/dollar.png' alt='' />
                <h4>Profit-sharing</h4>
                <p>
                  We believe in giving back to our team, that’s why we give back
                  profits to our team
                </p>
              </div>

              <div className={styles.career_offer_content_inner}>
                <img src='/dollar.png' alt='' />
                <h4>Health-insurance</h4>
                <p>
                  We’ll cover your health insurance premiums across different
                  countries you’re in.
                </p>
              </div>

              <div className={styles.career_offer_content_inner}>
                <img src='/dollar.png' alt='' />
                <h4>Remote set-up</h4>
                <p>
                  Just getting started at home? We’ll sort out your work from
                  home set-up.
                </p>
              </div>

              <div className={styles.career_offer_content_inner}>
                <img src='/dollar.png' alt='' />
                <h4>Education stipend</h4>
                <p>
                  Want to buy a course? We’ve got you. Anything you need to help
                  progress, it’s on us.
                </p>
              </div>
            </div>
            <div className={styles.career_offer_content2}>
              <div className={styles.career_offer_content_inner2}>
                <img src='/dollar.png' alt='' />
                <h4>Off-sites</h4>
                <p>
                  Are you ready for your summer holidays? Because  we are,
                  all paid for.
                </p>
              </div>

              <div className={styles.career_offer_content_inner2}>
                <img src='/dollar.png' alt='' />
                <h4>And more...</h4>
                <p>
                  That’s not it, we’ve got a whole host more benefits for when
                  you join.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.career_open_positions_wrapper}>
          <div className={styles.career_open_positions_content}>
            <h3>Open Positions</h3>
            <div>
              <div>
                {career.map((data) => (
                  <>
                    <div
                      className={styles.career_open_positions_inner}
                      key={data.id}>
                      <div>
                        <h4>{data.position}</h4>
                        <p>{data.role}</p>
                      </div>
                      <Link
                        to={'/Career/Career/' + data.slug}
                        className={styles.career_open_positions__link}>
                        View job post
                      </Link>
                    </div>
                    <hr />
                  </>
                ))}
              </div>

              <div className={styles.suggestion}>
                <p>
                  Don't see your role here?<span> email us</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query MyQuery {
    allJobDummyJson {
      nodes {
        position
        role
        id
        slug
      }
    }
  }
`;
